import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom'
import Select from 'react-select';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Modal from 'react-bootstrap/Modal'
const axios = require('axios');
const lRange = require('lodash/range');

class ReapitAutomaticMessage extends React.Component {
  constructor(props) {
    super(props);
    const msg = props.automatic_message
    const timeOffsetInMinutes = msg.time_offset_in_minutes
    this.state = {
      selectedCategoryId: (msg.category === null ? "" : msg.category.toString()),
      selectedEventType: (msg.event_type === null ? "" : msg.event_type.toString()),
      sendAtFixedTime: (msg.send_at_fixed_time ?? ''),
      scheduleSend: msg.scheduled,
      templateText: (msg.text_template === null ? "" : msg.text_template),
      contentIntroductionText: (msg.presentation_template ?? ""),
      feedbackEmailAddresses: (msg.feedback_email_addresses ?? ""),
      onlyTriggerEmailAddress: (msg.feedback_to_only_trigger_email_address),
      dataloft: msg.dataloft,
      active: msg.active,
      isCategoriesLoaded: false,
      categories: [],
      propertyTypes: [],
      triggerActions: [],
      scheduleTypes: [],
      accompaniedTypes: [],
      scheduleType: (msg.schedule_type === null ? '' : msg.schedule_type.toString()),
      isPropertyTypesLoaded: false,
      isTriggerActionsLoaded: false,
      years: Math.abs(Math.trunc(timeOffsetInMinutes / 525600)),
      days: Math.abs(Math.trunc(timeOffsetInMinutes / 1440 % 365)),
      hours: Math.abs(Math.trunc(timeOffsetInMinutes / 60 % 24)),
      minutes: Math.abs(timeOffsetInMinutes % 60),
      whenToSchedule: (timeOffsetInMinutes > 0 ? 1 : -1),
      templates: [],
      cachedConfigurations: [],
      recipientTypes: [],
      selectedRecipientTypes: props.automatic_message.reapit_foundations_message_recipient_options.map(recipient_option => ({value: recipient_option.id, label: recipient_option.name})),
      insertFromTemplateModal: false,
      recipientOptions: props.automatic_message.reapit_foundations_message_recipient_options.map(recipient_option => ({value: recipient_option.id, label: recipient_option.name})),
      propertyType: msg.property_type_restriction,
      accompanied: msg.accompanied,
      triggerAction: (msg.event_type === null ? "" : msg.trigger_action.toString()),
      appointmentAtFixedTime: msg.appointment_at_fixed_time,
    };
    this.csrfToken = document.querySelector('meta[name=csrf-token]').content;
    this.selectRef = React.createRef();
  }

  handleTriggerEmailAddress = (event) => {
    this.setState({onlyTriggerEmailAddress: event.target.checked})
  }

  activeChanged = (event) => {
    this.setState({active: event.target.checked})
  }

  handleDataloftChange = (event) => {
    this.setState({dataloft: event.target.checked})
  }

  contentIntroductionTextChanged = (event) => {
    this.setState({contentIntroductionText: event.target.value})
  }

  feedbackEmailAddressChanged = (event) => {
    this.setState({feedbackEmailAddresses: event.target.value})
  }

  sendAtFixedTimeChanged = (event) => {
    this.setState({sendAtFixedTime: event.target.value})
  }

  handlePropertyTypeChange = (event) => {
    this.setState({propertyType: event.target.value})
  }

  handleAccompaniedChange = (event) => {
    this.setState({accompanied: event.target.value})
  }

  handleCategoryChange = (event) => {
    this.setState({selectedCategoryId: event.target.value, selectedEventType: "", recipientOptions: []})
  }

  handleEventTypeChange = (event) => {
    this.setState({selectedEventType: event.target.value, recipientOptions: []})
  }

  scheduleSendChanged = (event) => {
    this.setState({scheduleSend: event.target.checked})
  }

  yearsChanged = (event) => {
    this.setState({years: event.target.value})
  }

  daysChanged = (event) => {
    this.setState({days: event.target.value})
  }

  hoursChanged = (event) => {
    this.setState({hours: event.target.value})
  }

  minutesChanged = (event) => {
    this.setState({minutes: event.target.value})
  }

  scheduleWhenChanged = (event) => {
    this.setState({whenToSchedule: event.target.value})
  }

  templateTextChanged = (event) => {
    this.setState({templateText: event.target.value})
  }

  showTemplateModal = (event) => {
    this.setState({insertFromTemplateModal: true})
  }

  hideTemplateModal = (event) => {
    this.setState({insertFromTemplateModal: false})
  }

  setTextTemplate(textTemplate) {
    this.setState({templateText: textTemplate, insertFromTemplateModal: false})
  }

  recipientOptionsChanged = (options) => {
    this.setState({recipientOptions: options})
  }

  recipientTypesChanged = (options) => {
    this.setState({selectedRecipientTypes: options})
  }

  handleTriggerActionChange = (event) => {
    this.setState({triggerAction: event.target.value})
  }

  handleScheduleTypeChange = (event) => {
    this.setState({scheduleType: event.target.value})
  }

  handleAppointmentAtFixedTimeChanged = (event) => {
    this.setState({appointmentAtFixedTime: event.target.checked})
  }

  componentDidMount() {
    axios.get('/code_descriptions/type_40.json').then((response) => this.setState({categories: response.data, isCategoriesLoaded: true}))
    axios.get('/code_descriptions/type_183.json').then((response) => this.setState({propertyTypes: response.data, isPropertyTypesLoaded: true}))
    axios.get('/code_descriptions/type_185.json').then((response) => this.setState({triggerActions: response.data, isTriggerActionsLoaded: true}))
    axios.get('/code_descriptions/type_190.json').then((response) => this.setState({scheduleTypes: response.data}))
    axios.get('/code_descriptions/type_191.json').then((response) => this.setState({accompaniedTypes: response.data}))
    axios.get('/reapit_foundations_templates.json').then((response) => this.setState({templates: response.data}))
    axios.get('/cached_configurations.json').then((response) => this.setState({cachedConfigurations: response.data}))
    axios.get('/reapit_foundations_message_recipient_options.json').then((response) => this.setState({recipientTypes: response.data}))
  }

  render() {
    if (this.state.isCategoriesLoaded == false || this.state.isPropertyTypesLoaded == false) {
      return(
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )
    } else {
      const popover = (
        <Popover id="popover-1">
          <Popover.Body>
            Set Time by changing Hour(s) and Minute(s) to when you want to send your message
          </Popover.Body>
        </Popover>
      );

      const messageTemplateInfoPopover = (
        <Popover id="popover-2">
          <Popover.Body>
            Click the template you require to add it to the message box. It can then be edited if desired.
          </Popover.Body>
        </Popover>
      )

      const messageTemplatePrefixSuffixPopover = (
        <Popover id="popover-3">
          <Popover.Body>
            {this.props.placeholderInfoText}
          </Popover.Body>
        </Popover>
      )

      const contentIntroductionPopover = (
        <Popover id="popover-4">
          <Popover.Body>
            Message at the top of the information page that explains what document/video/URL is and what it's used for.
          </Popover.Body>
        </Popover>
      )

      const dataloftPopover = (
        <Popover id="popover-4">
          <Popover.Body>
            House sales/tenancy statistics for your business, localised to the property area
          </Popover.Body>
        </Popover>
      )

      const activatePopover = (
        <Popover id="popover-4">
          <Popover.Body>
            Activate this template by clicking enabled or disable this template by clicking disabled
          </Popover.Body>
        </Popover>
      )

      var size = 160;

      var messageLength = this.state.templateText.length;
      messageLength += (this.state.templateText.match(/\u00A3/g) || []).length;

      var placeHolderLength = this.props.placeholder.length;
      placeHolderLength += (this.props.placeholder.match(/\u00A3/g) || []).length;

      messageLength = messageLength + placeHolderLength;

      if(messageLength > 160) {
        size = 154;
      }

      var credits = Math.ceil(messageLength / size);

      if(credits === 0) {
        credits++;
      }

      var messageLimit = credits * size;
      var countCharactersText = "" + messageLength + "/" + messageLimit + ". Credits used: " + credits;

      const parsedYears = parseInt(this.state.years);
      const parsedDays = parseInt(this.state.days);
      const parsedHours = parseInt(this.state.hours);
      const parsedMinutes = parseInt(this.state.minutes);
      const parsedWhen = parseInt(this.state.whenToSchedule);

      const calculatedMinutes = ((parsedYears * 365 * 24 * 60) + (parsedDays * 24 * 60) + (parsedHours * 60) + parsedMinutes) * parsedWhen;

      let calculateMinutesResult;

      if (calculatedMinutes === 0 && parsedWhen === 1) {
        calculateMinutesResult = 1;
      } else {
        calculateMinutesResult = calculatedMinutes;
      }

      const legacyCategorySelected = ['36','37','43','53','59','178','179','180','181','182','184','186','187','188','189','192'].includes(this.state.selectedCategoryId);
      const certificateCategorySelected = this.state.selectedCategoryId === '194';

      return(
        <div className="container-fluid">
          <form encType="multipart/form-data" action={this.props.submitUrl} acceptCharset="UTF-8" method="post">
            {this.props.automatic_message.id !== null && <input type="hidden" name="_method" value="patch"/>}
            <input type="hidden" name="authenticity_token" value={this.csrfToken} />
            <div className="row mb-3">
              <label htmlFor="reapit_foundations_message_category" className="col-lg-2 col-form-label">Category:</label>
              <div className="col-lg-4">
                <select className="form-select" id="reapit_foundations_message_category" name="reapit_foundations_message[category]" value={this.state.selectedCategoryId} onChange={this.handleCategoryChange}>
                  <option value="">Choose category</option>
                  {this.state.categories.map((category) => <option key={category.id_value} value={category.id_value}>{category.description}</option>)}
                </select>
              </div>
              {this.state.selectedCategoryId !== "" &&
                <>
                  <label htmlFor="reapit_foundations_message_event_type" className="col-lg-2 col-form-label">Event Type:</label>
                  <div className="col-lg-4">
                    <select className="form-select" id="reapit_foundations_message_event_type" name="reapit_foundations_message[event_type]" value={this.state.selectedEventType} onChange={this.handleEventTypeChange}>
                      <option value="">Choose Event Type</option>
                      {legacyCategorySelected && this.state.categories.find(category => category.id_value == this.state.selectedCategoryId).event_types.map((event_type) => <option key={event_type.id_value} value={event_type.id_value}>{event_type.description}</option>)}
                      {certificateCategorySelected && this.state.cachedConfigurations.filter(cached_conf => cached_conf.type_id === 194).map(cert_conf => <option key={cert_conf.option_id} value={cert_conf.option_id}>{cert_conf.config_value}</option>)}
                    </select>
                  </div>
                </>
              }
            </div>
            {this.state.selectedCategoryId !== "" && this.state.selectedEventType !== "" &&
              <>
                <div className="row mb-3">
                  <label htmlFor="reapit_foundations_message_trigger_action" className="col-lg-2 col-form-label">Action</label>
                  <div className="col-lg-4">
                    <select className="form-select" id="reapit_foundations_message_trigger_action" name="reapit_foundations_message[trigger_action]" value={this.state.triggerAction} onChange={this.handleTriggerActionChange} required={true}>
                      <option value="">Choose Trigger Action</option>
                      {this.state.triggerActions.map((triggerAction) => <option key={triggerAction.id_value} value={triggerAction.id_value}>{triggerAction.description}</option>)}
                    </select>
                  </div>
                </div>
                {['1', '5'].includes(this.state.triggerAction) &&
                  <>
                    <div className="row mb-3">
                      <label htmlFor="reapit_foundations_message_reapit_foundations_message_recipient_option_ids" className="col-lg-2 col-form-label">Recipient:</label>
                      <div className="col-lg-10">
                        {legacyCategorySelected && <Select value={this.state.recipientOptions} onChange={this.recipientOptionsChanged} inputId="reapit_foundations_message_reapit_foundations_message_recipient_option_ids" name="reapit_foundations_message[reapit_foundations_message_recipient_option_ids][]" isMulti options={this.state.categories.find(category => category.id_value == this.state.selectedCategoryId).event_types.find(event_type => event_type.id_value == this.state.selectedEventType).recipient_options.map(recipient_option => ({value: recipient_option.id, label: recipient_option.name}))} />}
                        {certificateCategorySelected && <Select value={this.state.selectedRecipientTypes} onChange={this.recipientTypesChanged} inputId="reapit_foundations_message_reapit_foundations_message_recipient_option_ids" name="reapit_foundations_message[reapit_foundations_message_recipient_option_ids][]" isMulti options={this.state.recipientTypes.map(recipientType => ({value: recipientType.id, label: recipientType.name}))} />}
                      </div>
                    </div>

                    <div className="row">
                      <label htmlFor="reapit_foundations_message_scheduled" className="col-lg-2 col-form-label">
                        Timing:
                        <OverlayTrigger overlay={popover}>
                          <i role="button" className="bi bi-info-circle-fill iiblue ms-1"></i>
                        </OverlayTrigger>
                      </label>
                      <div className="col-lg-10">
                        <label className="form-check-label me-2" htmlFor="reapit_foundations_message_scheduled">Immediately</label>
                        <div className="form-check form-switch d-inline-block">
                          <input name="reapit_foundations_message[scheduled]" type="hidden" value="0"/>
                          <input name="reapit_foundations_message[scheduled]" checked={this.state.scheduleSend} onChange={this.scheduleSendChanged} className="form-check-input" type="checkbox" role="switch" id="reapit_foundations_message_scheduled"/>
                          <label className="form-check-label" htmlFor="reapit_foundations_message_scheduled">Schedule</label>
                        </div>
                      </div>
                    </div>

                    {this.state.scheduleSend === true &&
                      <>
                        <div className="row mb-3">
                          <div className="col-lg-4 offset-lg-2">
                            <select className="form-select" id="reapit_foundations_message_schedule_type" name="reapit_foundations_message[schedule_type]" value={this.state.scheduleType} onChange={this.handleScheduleTypeChange} required={true}>
                              <option value="">Choose Schedule Type</option>
                              {this.state.scheduleTypes.map((scheduleType) => <option key={scheduleType.id_value} value={scheduleType.id_value}>{scheduleType.description}</option>)}
                            </select>
                          </div>
                        </div>
                        {this.state.scheduleType === '19001' &&
                          <div className="row mb-3">
                            <div className="col-lg-10 offset-lg-2">
                              <div className='d-inline-block'>
                                <label htmlFor="scheduleYears">Year(s)</label>
                                <select value={this.state.years} onChange={this.yearsChanged} className="form-select d-inline-block ms-1 me-5" style={{width: '5rem'}} id="scheduleYears" disabled={this.state.scheduleSend === false}>
                                  {lRange(10).map(year => <option key={year} value={year}>{year}</option> )}
                                </select>

                                <label htmlFor="scheduleHours">Days(s)</label>
                                <select value={this.state.days} onChange={this.daysChanged} className="form-select d-inline-block ms-1  me-5" style={{width: '5rem'}} id="scheduleHours" disabled={this.state.scheduleSend === false}>
                                  {lRange(365).map(day => <option key={day} value={day}>{day}</option> )}
                                </select>

                                <label htmlFor="scheduleHours">Hour(s)</label>
                                <select value={this.state.hours} onChange={this.hoursChanged} className="form-select d-inline-block ms-1  me-5" style={{width: '5rem'}} id="scheduleHours" disabled={this.state.scheduleSend === false}>
                                  {lRange(24).map(hour => <option key={hour} value={hour}>{hour}</option> )}
                                </select>

                                <label className="ms-1" htmlFor="scheduleMinutes">Minute(s)</label>
                                <select value={this.state.minutes} onChange={this.minutesChanged} className="form-select d-inline-block ms-1  me-5" style={{width: '5rem'}} id="scheduleMinutes" disabled={this.state.scheduleSend === false}>
                                  {lRange(60).map(minute => <option key={minute} value={minute}>{minute}</option> )}
                                </select>

                                <select value={this.state.whenToSchedule} disabled={this.state.scheduleSend === false} onChange={this.scheduleWhenChanged} className="form-select d-inline-block ms-1" required={true} style={{width: '10rem'}}>
                                  <option value={-1}>Before</option>
                                  <option value={+1}>After</option>
                                </select>

                                <input name="reapit_foundations_message[time_offset_in_minutes]" type="hidden" value={calculateMinutesResult}/>
                              </div>
                            </div>
                          </div>
                        }
                        {this.state.scheduleType == '19002' &&
                          <div className="row mb-3">
                            <div className="col-sm-10 offset-lg-2">
                              <input type="text" readOnly={true} className="form-control-plaintext" id="reminder-before-fixed" value={"This value is set by Speedway support team"}/>
                            </div>
                          </div>
                        }
                        {['19003', '19004'].includes(this.state.scheduleType) &&
                          <div className="row mb-3">
                            <div className="col-lg-4 col-md-10 col-sm-10 col-sm-10 offset-md-2">
                              <input type="time" className="form-control" value={this.state.sendAtFixedTime} onChange={this.sendAtFixedTimeChanged} id="reapit_foundations_message_send_at_fixed_time" name="reapit_foundations_message[send_at_fixed_time]"></input>
                            </div>
                          </div>
                        }
                      </>
                    }

                    <div className="row mb-3">
                      <label htmlFor="reapit_foundations_message_text_template" className="col-lg-2 col-form-label">
                        <div>SMS Message:</div>
                        <button type="button" className="btn btn-primary" onClick={this.showTemplateModal}>Insert from Template:</button>
                        <Modal size="xl" show={this.state.insertFromTemplateModal} onHide={this.hideTemplateModal}>
                          <Modal.Header closeButton>
                            <Modal.Title>Select the message template you wish to insert</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="d-grid gap-2">
                              {this.state.templates.filter(template => template.event_type == this.state.selectedEventType).length > 0 && this.state.templates.filter(template => template.event_type == this.state.selectedEventType).map(template => <button className="btn btn-outline-secondary" key={template.id} type="button" onClick={this.setTextTemplate.bind(this, template.text_template)}>{template.text_template}</button>)}
                              {this.state.templates.filter(template => template.event_type == this.state.selectedEventType).length === 0 && <h4 className="text-center">No templates for the selected anchor type</h4>}
                            </div>
                          </Modal.Body>
                        </Modal>
                        <OverlayTrigger overlay={messageTemplateInfoPopover}>
                          <i role="button" className="bi bi-info-circle-fill iiblue ms-1"></i>
                        </OverlayTrigger>
                      </label>
                      <div className="col-lg-10">
                        <textarea className="form-control" rows="8" value={this.state.templateText} onChange={this.templateTextChanged} placeholder={this.props.placeholder} required={true} id="reapit_foundations_message_text_template" name="reapit_foundations_message[text_template]"></textarea>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="countMessageTextSize" className="col-lg-2 col-form-label">Count:</label>
                      <div className="col-lg-10">
                        <span className='form-control-plaintext d-inline-block' style={{width: "initial"}}>{countCharactersText}</span>
                        <OverlayTrigger overlay={messageTemplatePrefixSuffixPopover}>
                          <i role="button" className="bi bi-info-circle-fill iiblue ms-1"></i>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="reapit_foundations_message_presentation_template" className="col-lg-2 col-form-label">
                        Content Introduction
                        <OverlayTrigger overlay={contentIntroductionPopover}>
                          <i role="button" className="bi bi-info-circle-fill iiblue ms-1"></i>
                        </OverlayTrigger>
                      </label>
                      <div className="col-lg-10">
                        <textarea className="form-control" rows="3" value={this.state.contentIntroductionText} onChange={this.contentIntroductionTextChanged} id="reapit_foundations_message_presentation_template" name="reapit_foundations_message[presentation_template]"></textarea>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="reapit_foundations_message_feedback_email_addresses" className="col-lg-2 col-form-label">
                        Feedback Email
                      </label>
                      <div className="col-lg-10">
                        <input type="text" className="form-control" value={this.state.feedbackEmailAddresses} onChange={this.feedbackEmailAddressChanged} id="reapit_foundations_message_feedback_email_addresses" name="reapit_foundations_message[feedback_email_addresses]"></input>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-10 offset-lg-2">
                        <div className="form-check">
                          <input name="reapit_foundations_message[feedback_to_only_trigger_email_address]" type="hidden" value="0"/>
                          <input className="form-check-input" type="checkbox" name="reapit_foundations_message[feedback_to_only_trigger_email_address]" id="reapit_foundations_message_feedback_to_only_trigger_email_address" checked={this.state.onlyTriggerEmailAddress} onChange={this.handleTriggerEmailAddress} />
                          <label className="form-check-label" htmlFor="reapit_foundations_message_feedback_to_only_trigger_email_address">
                            Send Feedback to this email only
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="reapit_foundations_message_property_type_restriction" className="col-lg-2 col-form-label">
                        Property Restriction
                      </label>
                      <div className="col-lg-4">
                        <select className="form-select" id="reapit_foundations_message_property_type_restriction" name="reapit_foundations_message[property_type_restriction]" value={this.state.propertyType} onChange={this.handlePropertyTypeChange}>
                          {this.state.propertyTypes.map((propertyType) => <option key={propertyType.id_value} value={propertyType.id_value}>{propertyType.description}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="reapit_foundations_message_accompanied" className="col-lg-2 col-form-label">
                        Accompanied Restriction
                      </label>
                      <div className="col-lg-4">
                        <select className="form-select" id="reapit_foundations_message_accompanied" name="reapit_foundations_message[accompanied]" value={this.state.accompanied} onChange={this.handleAccompaniedChange}>
                          {this.state.accompaniedTypes.map((accompaniedType) => <option key={accompaniedType.id_val} value={accompaniedType.id_val}>{accompaniedType.description}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="reapit_foundations_message_dataloft" className="col-lg-2 col-form-label">Dataloft:</label>
                      <div className="col-lg-10">
                        <input name="reapit_foundations_message[dataloft]" type="hidden" value="0"/>
                        <input className="form-check-input me-1" type="checkbox" name="reapit_foundations_message[dataloft]" id="reapit_foundations_message_dataloft" checked={this.state.dataloft} onChange={this.handleDataloftChange} />
                        <label className="form-check-label" htmlFor="reapit_foundations_message_dataloft">
                          Enabled?
                        </label>
                        <OverlayTrigger overlay={dataloftPopover}>
                          <i role="button" className="bi bi-info-circle-fill iiblue ms-1"></i>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </>
                }
                {this.state.triggerAction === '3' &&
                  <div className="row mb-3">
                    <label htmlFor="reapit_foundations_message_send_at_fixed_time" className="col-lg-2 col-form-label">
                      Step Survey Fixed time
                    </label>
                    <div className="col-lg-4 col-md-10">
                      <input type="time" className="form-control" value={this.state.sendAtFixedTime} onChange={this.sendAtFixedTimeChanged} id="reapit_foundations_message_send_at_fixed_time" name="reapit_foundations_message[send_at_fixed_time]"></input>
                    </div>
                  </div>
                }

                <div className="row mb-3">
                  <label htmlFor="reapit_foundations_message_active" className="col-lg-2 col-form-label">This template</label>
                  <div className="col-lg-10">
                    <label className="form-check-label me-2" htmlFor="reapit_foundations_message_active">Disabled</label>
                    <div className="form-check form-switch d-inline-block">
                      <input name="reapit_foundations_message[active]" type="hidden" value="0"/>
                      <input checked={this.state.active} onChange={this.activeChanged} className="form-check-input" type="checkbox" role="switch" id="reapit_foundations_message_active" name="reapit_foundations_message[active]"/>
                      <label className="form-check-label" htmlFor="reapit_foundations_message_active">Enabled</label>
                    </div>
                    <OverlayTrigger overlay={activatePopover}>
                      <i role="button" className="bi bi-info-circle-fill iiblue ms-1"></i>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-10 offset-lg-2">
                    <input className="btn btn-primary me-3" type="submit" name="commit" value="Save" data-disable-with="Saving"/>
                    {<input className="btn btn-primary me-3" type="submit" name="commit" value="Save and Add attachments" data-disable-with="Saving"/>}
                    <a className="btn btn-info" href="/automatic_messages" role="button">Back</a>
                  </div>
                </div>
              </>
            }
          </form>
        </div>
      );
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('reapit-automatic-message')
  if (node != null) {
    const data = JSON.parse(node.getAttribute('data'))
    ReactDOM.render(<React.StrictMode><ReapitAutomaticMessage {...data} /></React.StrictMode>, node)
  }
})
